import React from 'react';
import PhotoSN from '../../assets/profile-photo.png';
import CTA from '../header/CTA';

const About = () => {
  return (
    <section id='about' className='2xl:w-9/12 2xl:mx-auto'>
      <h5 className='text-xs'>Un poco</h5>
      <h2 className='text-xl'>Sobre mi</h2>
      <div className='container grid gap-8'>
        <div className=' rounded-full p-[1px] grid place-items-center'>
          <div className=' border-yellow-300 border-2 overflow-hidden sm:flex sm:justify-center'>
            <img
              src={PhotoSN}
              alt='Avatar'
              className='sm:w-6/12 rounded-full'
            />
          </div>
        </div>
        <div className='lg:w-9/12 text-center mx-auto md:mt-6 flex flex-col justify-center items-center gap-4 font-extralight'>
          <p>
            Apasionado desarrollador fullstack con más de dos años de
            experiencia en la creación de aplicaciones escalables y eficientes.
            Mi enfoque se centra en tecnologías como Node.js, PHP, React y bases
            de datos relacionales como PostgreSQL, así como base de datos no
            relacionales como MongoDB. Además, cuento con conocimientos en
            tecnologías Cloud como AWS.
          </p>
        </div>
        <CTA />
      </div>
    </section>
  );
};

export default About;
