import React from 'react';

import Mazenod from '../../assets/portfolio/mazenod.png';
import ITTalent from '../../assets/portfolio/ittalent.png';
import PatrimonialAxarquia from '../../assets/portfolio/patrimonialaxarquia.png';
import ConsulRent from '../../assets/portfolio/consulrent.png';
import InventraAPP from '../../assets/portfolio/inventra-app.png';
import Grupo6 from '../../assets/portfolio/grupo6.png';

const data = [
  {
    id: 0,
    image: InventraAPP,
    title: 'Inventra',
    description: 'Aplicación para gestión de inventario',
    technnologies: 'React | Node | Mongo',
    tags: '',
    github: 'https://github.com/No-Country/c12-27-m-node-react',
    demo: 'https://inventra-nocontry-one.vercel.app/auth/routes/login',
  },
  {
    id: 1,
    image: Mazenod,
    title: 'Mazenod',
    description: 'Página Web de institución educativa',
    technnologies: 'React | Node | Mongo | AWS',
    tags: '',
    github: '',
    demo: 'https://mazenod.rilow.technology/',
  },
  {
    id: 2,
    image: ITTalent,
    title: 'ITTalent',
    description: 'SPA para empresa de reclutamiento IT',
    technnologies: 'React | Tailwind',
    tags: '',
    github: '',
    demo: 'https://ittalent.pe',
  },
  {
    id: 3,
    image: PatrimonialAxarquia,
    title: 'Patrimonial Axarquía',
    description: 'Página web para alquiler de departamentos',
    technnologies: 'WordPress | Elementor',
    tags: '',
    github: '',
    demo: 'https://patrimonialaxarquia.com',
  },
  {
    id: 4,
    image: Grupo6,
    title: 'Grupo 6',
    description: 'Página web de Alquiler de Propiedades',
    technnologies: 'Wordpress | PHP',
    tags: '',
    github: '',
    demo: 'https://www.grupo-6.com/',
  },
  {
    id: 5,
    image: ConsulRent,
    title: 'ConsulRent',
    description: 'Aplicación para reserva de consultorios',
    technnologies: 'React | Node | Mongo',
    tags: '',
    github: 'https://github.com/PERCYC0DE',
    demo: '',
  },
];

const Portfolio = () => {
  return (
    <section
      id='portfolio'
      className='2xl:w-9/12 2xl:mx-auto border-4 border-yellow-300'
    >
      <h5 className='text-xs'>Algunos de mis</h5>
      <h2 className='text-xl'>Proyectos</h2>

      <div className='grid gap-9 mx-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 2xl:w-9/12 2xl:mx-auto'>
        {data.map(
          ({ id, image, title, description, github, demo, technnologies }) => {
            return (
              // Card Portfolio Item
              <article
                className='border border-solid border-slate-500 bg-bgVariant rounded-2xl transition-transition hover:bg-transparent hover:border hover:border-colorPrimary'
                key={id}
              >
                <div className='overflow-hidden h-[200px] rounded-t-xl'>
                  <img
                    src={image}
                    alt={title}
                    className='h-full object-cover'
                  />
                </div>
                <div className='px-2'>
                  <h3 className='mt-4 text-lg  uppercase'>{title}</h3>
                  <span className='text-sm font-extralight text-slate-400'>
                    {description}
                  </span>
                  {/* TODO: Generate dinamic content */}
                  <p className='text-xs mb-6 mt-2 font-extralight text-gray-500'>
                    {technnologies}
                  </p>
                  <div className='flex gap-4 my-4'>
                    <a
                      href={github}
                      className={`${github === '' ? 'hidden' : 'btn'}`}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Github
                    </a>
                    <a
                      href={demo}
                      className={`${
                        demo === '' ? 'hidden' : 'btn btn-primary'
                      }`}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Ver Demo
                    </a>
                  </div>
                </div>
              </article>
            );
          }
        )}
      </div>
    </section>
  );
};

export default Portfolio;
