import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub, FaTwitter } from "react-icons/fa";

const HeaderSocials = () => {
  return (
    // <div className="after:content-[''] after:w-1 after:h-4 after:bg-colorPrimary flex sm:flex-col items-center gap-3 absolute left-0 sm:bottom-12">
    <div className="flex justify-center mt-4 sm:flex-col sm:items-center gap-3 sm:absolute sm:left-0 sm:bottom-12">
      <a
        href="https://linkedin.com/in/percycode"
        target={"_blank"}
        rel="noreferrer"
      >
        <BsLinkedin size={20} />
      </a>

      <a
        href="https://twitter.com/percycode"
        target={"_blank"}
        rel="noreferrer"
      >
        <FaTwitter size={20} />
      </a>
      <a href="https://github.com/PERCYC0DE" target={"_blank"} rel="noreferrer">
        <FaGithub size={20} />
      </a>
    </div>
  );
};

export default HeaderSocials;
